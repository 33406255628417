import React, { Component } from 'react';


class LoadingProduct extends Component {



	render(){
    
		return(
<section id="booking" style={{ backgroundColor: "#ffffff" }}>

  <div className="container">
    <div className="row">
      <div className="col-lg-7 mx-auto">
      <div style={{ height: "35px" }}></div>

                  <div className="ph-item border-0 pl-0 pr-0">
                      <div className="ph-col-12 pl-0 pr-0">
                          <div className="ph-picture"></div>
                          <div className="ph-row">
                            <div className="ph-col-12 big"></div>
                            <div className="ph-col-12 empty"></div>

                            <div className="ph-col-2"></div>
                            <div className="ph-col-2 empty"></div>
                            <div className="ph-col-2"></div>
                            <div className="ph-col-2 empty"></div>
                            <div className="ph-col-2"></div>
                            <div className="ph-col-2 empty"></div>
                            <div className="ph-col-12 empty"></div>

                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12 empty"></div>


      <ul className="nav nav-tabs nav-justified w-100" id="myTab" role="tablist">
          <li className="nav-item">
            <a className="nav-link active text-theme" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true"><b>Description</b></a>
          </li>
      </ul>
      <div className="tab-content">
          <div className="tab-pane fade show active mt-4" id="description" role="tabpanel" aria-labelledby="description-tab">
            <div>
              <div />
            </div>
            <div>
               
            </div>
            <div>
               
            </div>
            <div>
              
            </div>
            <div>
               
            </div>
        </div>
      </div>
      <div className="ph-col-12 empty"></div>
      <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12 empty"></div>




                          </div>
                      </div>
                  </div>


      </div>
      <div className="col-lg-5">
      <div style={{ height: "64px" }}></div>
        

      <div className="card mb-4 shadow p-2">
        <div className="card-body pb-0">
                  <div className="ph-item border-0 pb-0">
                      <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-12 big"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-12 empty"></div>
                            
                          </div>
                      </div>
                  </div>
        </div>
      </div>

      <div className="card mb-4 shadow p-2">
        <div className="card-header">
                  <h3>
                  <i className="fa-solid fa-ticket"></i> Book 
                  
                  </h3>
                    Secure booking — only takes 2 minutes!
            
            </div>
            <div className="mt-4">
                  <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-2 big"></div>
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-2 big"></div>
                            </div>
                            <div className="ph-picture"></div>
                            <div className="ph-row">
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-4"></div>
                            </div>
                        </div>
                        
                        
                  </div>
                  <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-6 big"></div>
                                <div className="ph-col-6 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                        </div>
                  </div>
                  <div className="ph-item">
                        
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-6 big"></div>
                                <div className="ph-col-6 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                        </div>
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-4 big"></div>
                            </div>
                        </div>
                  </div>
              </div>
      </div>





      </div>
    </div>
  </div>

</section>
			)
	}
}

export default LoadingProduct;