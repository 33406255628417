import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import { Helmet, HelmetProvider } from "react-helmet-async";
import LoadingCategory from './LoadingCategory';


class Category extends Component {
  
  constructor() {
    super()

    if (localStorage.getItem('sessionId') === null) {
      window.location.reload(false);
    }
    
    this.state = {
      category: [],
      redirect: ''
    }
  }

  fetchApi()
  {
        

        this.setState({
          category: [],
        })
       
          
         
       
      const {slug} = this.props.match.params;
    
      fetch(process.env.REACT_APP_API_URL+`/category/${slug}`)
      .then(response => response.json())
      .then((json) => {

        this.setState({
          category: json.category
        })
        

       })
      .catch((e) => {
        try
        {
          this.setState({
            redirect: '/page/not/found'
          });
        }
        catch(error)
        {

        }
        
       
    })

      

  }

  

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchApi();
  }



  componentDidUpdate = (prevProps) => {
    if(this.props.match.params.slug !== prevProps.match.params.slug ) {
     window.scrollTo(0, 0);
     this.fetchApi();
    };
  };



  render(){



    if(this.state.redirect!==""){
      let redirectUrl = this.state.redirect;
      this.setState({
        redirect: ''
      })
      return <Redirect to={redirectUrl} />
    }

    if(this.state.category.length===0)
    {
      return <LoadingCategory />
    }


    return (

<section id="tour" style={{backgroundColor: "#ffffff"}}>



{this.state.category.map((category, index) => {
  
  return(

<div className="container" key={index}>
  <HelmetProvider>
      <Helmet>

        <title>{ category.name }</title>
        <meta name="description" content={ localStorage.getItem('siteDescription') } />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content={category.name} />
        <meta name="og:description" content={ localStorage.getItem('siteDescription') } />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={category.name}/>
        <meta name="twitter:description" content={ localStorage.getItem('siteDescription') } />
        

      </Helmet>
  </HelmetProvider>
  <div id="containerCategory" className="row">
    <div className="col-lg-12 col-md-12 mx-auto">
      <div className="row" style={{paddingBottom: "0px"}}>
        <div className="col-lg-12 text-center">
          <div style={{height: "70px"}}></div>
            <h3 className="section-heading" style={{marginTop: "0px"}}>{ category.name }</h3>
            {(() => {
              if(category.description !== undefined)
              {
                 return (
                    <h5>{ category.description }</h5>
                 )
              } 
            })()}
            

            <hr className="hr-theme" />
            <div style={{height: "30px"}}></div>
          </div>
        </div>
        <div className="row" style={{ paddingBottom: "0px", minHeight: "300px"}}>
          <div className="col-lg-12 text-center">



              <div className="row">
             { category.products.map((product,index) => {
                  let cover = ("");
                  let excerpt = ("");
                  let rating = ("");
                  

                  if(product.cover!=="")
                  {
                    cover = (
                      <div className="container-book">
                        <Link to={{ pathname: "/tour/"+ product.slug }}  className="text-decoration-none">
                          <img src={product.cover} alt={product.name} className="card-img-top image-book"  />
                        </Link>
                        <div className="middle-book">
                          <Link to={{ pathname: "/tour/"+ product.slug }}  className="btn btn-theme btn-md p-3" style={{ borderRadius: "0" }}>
                            BOOK NOW
                          </Link>
                        </div>
                      </div>
                      )
                  }

                  if(product.excerpt!=="")
                  {
                    excerpt = (
                      <div className="card-body pt-0 pb-0">
                        <p className="card-text text-left">
                          <span dangerouslySetInnerHTML={ {__html: product.excerpt} } />
                        </p>
                      </div>
                      )
                  }

                  if(product.rating!=="")
                  {
                    rating = (
                      <div className="card-body pt-0 pb-0">
                        <p className="card-text text-left">
                          <span dangerouslySetInnerHTML={ {__html: product.rating} } />
                        </p>
                      </div>
                      )
                  }

                  return (
                    <div className="col-lg-4 col-md-6 mb-4 d-flex" key={index}>
                      <div className="card shadow card-block rounded flex-fill">
                        { cover }
                        <div className="card-body bg-white border-0 text-left pb-0">
                          <h3 className="mb-2"><Link to={{ pathname: "/tour/"+ product.slug }}  className="text-theme text-decoration-none">{product.name}</Link></h3>
                        </div>
                        
                        <div className="card-body pt-0 pl-0 text-left">
                          
                            { rating }
                          
                            { excerpt }
                         
                        </div>

                        <div className="card-footer bg-white pt-0" style={{ border: "none" }}>
                          <div className="text-left mb-2">
                            <span className="text-muted"><i className="far fa-clock"></i> Duration : {product.duration}</span>
                          </div>
                          <div className="d-flex align-items-end mb-2">

                            <div className="p-0 ml-0">
                              
                              <div className="text-left">
                                <span className="text-muted">Start from</span>
                              </div>
                              <div>
                                <b style={{ fontSize: "24px" }}>
                                  {product.currency} {product.amount}
                                </b>
                              </div>
                            </div>
                            <div className="ml-auto p-0">
                              <Link to={{ pathname: "/tour/"+ product.slug }}  className="btn btn-theme btn-md "><i className="fa-solid fa-ticket"></i> Book now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    )

               })}
              </div>
               
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "40px"}}></div>  
  </div>

    )
  
  
})}


</section>


    	)
  }
}

export default Category;