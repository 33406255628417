import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Navbar, Nav }  from "react-bootstrap";



class Menu extends Component {

  constructor() {
    
    super()

     if (localStorage.getItem('sessionId') === null) {
      window.location.reload(false);
    }
    
    this.state = {
      categories: [],
      isNavExpanded: false,
    };
    
    this.setIsNavExpanded = (isNavExpanded) => {
      this.setState({ isNavExpanded: isNavExpanded });
    };

    this.handleClick = (e) => {
      
        this.setState({ isNavExpanded: false });
      
    }
  }


  componentDidMount() {
    document.addEventListener('click', this.handleClick, false); 
    fetch(process.env.REACT_APP_API_URL+'/'+ localStorage.getItem('sessionId') +'/navbar')
    .then(response => response.json())
    .then((json) => {
          this.setState({
              categories: json.categories,
              logo: json.logo
          });
    });
    
  }

  

  render(){
    
          

    return (
<Navbar collapseOnSelect expand="lg" variant="dark" className=" fixed-top shadow" style={{ backgroundColor: '#2e3338' }}>

<div className="container">
  <Link to="/"><img src={this.state.logo} alt="VERTIKAL TRIP" height="45"  style={{ marginTop: '9px', marginBottom: '9px', marginRight: '15px' }} /></Link>
  <Navbar.Toggle aria-controls="responsive-navbar-nav">
     <span className="navbar-toggler-icon"></span>
  </Navbar.Toggle>
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      {this.state.categories.map((category,index) => {
          return (
            <Nav.Link as={Link} className="menu-hover nav-link text-white font-weight-bold ml-2" to={{ pathname: "/tours/"+ category.slug }}  eventKey="0" key={index}><i className="fas fa-atlas fa-lg fa-fw"></i><span className="ml-1">{category.name}</span></Nav.Link>
            )     
        })}
    </Nav>
    <Nav className="navbar-nav text-uppercase  ml-auto mb-1">

            <Nav.Link as={Link} className="nav-link text-white font-weight-bold display-on-desktop ml-2 mt-2" to={{ pathname: "/booking/checkout" }} eventKey="0"><span className="display-on-desktop"><i className="fas fa-shopping-cart fa-lg fa-fw mr-2"></i>Shopping Cart</span></Nav.Link>
            <Nav.Link as={Link} className="nav-link text-white font-weight-bold display-on-desktop ml-2 mt-2" to={{ pathname: "/booking/lastorder" }} eventKey="0"><span className="display-on-desktop"><i className="fas fa-file-signature fa-lg fa-fw mr-2"></i>My Orders</span></Nav.Link>
            
            
    </Nav>
  </Navbar.Collapse>
</div>
</Navbar>




            )
  }
}

export default Menu;
