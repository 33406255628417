import React, {Component} from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import {Redirect} from 'react-router-dom';
import LoadingLastOrder from './LoadingLastOrder';
import EmptyOrder from './EmptyOrder';

class LastOrder extends Component {
  
  constructor() {
    super()

    if (localStorage.getItem('sessionId') === null) {
      window.location.reload(false);
    }

    this.state = {
      lastOrders: [],
      info: '',
      redirect: '',
      isEmpty: false
    }
    const openAppRoute = (redirectUrl) => {
      this.setState({
        redirect: redirectUrl
      });
    };
    window.openAppRoute = openAppRoute;
  }

  fetchApi()
  {
      fetch(process.env.REACT_APP_API_URL+"/ticket/"+ localStorage.getItem('sessionId') +"/last-order" )
      .then(response => response.json())
      .then((json) => {
        this.setState({
          lastOrders: json.booking,
          info: json.info
        });

          

          if(json.booking.length===0)
          {
            this.setState({
              isEmpty: true
            });
          }
       }).catch((e) => {
        this.setState({
          isEmpty: true
        });
      })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchApi();
  }

  
  render(){
  
  if(this.state.redirect!==""){
    let redirectUrl = this.state.redirect;
    return <Redirect to={redirectUrl} />
  }

  if(this.state.isEmpty===true)
  {
    return <EmptyOrder />
  }

  if(this.state.lastOrders.length===0)
    {
      return <LoadingLastOrder />
    }

  return (
    <section id="page" style={{ backgroundColor: "#ffffff" }}>
    <HelmetProvider>
    <Helmet>
        <title>My Orders</title>
        <meta name="description" content={ localStorage.getItem('siteDescription') } />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content="My Orders" />
        <meta name="og:description" content={ localStorage.getItem('siteDescription') } />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="My Orders"/>
        <meta name="twitter:description" content={ localStorage.getItem('siteDescription') } />
      </Helmet>
    </HelmetProvider>
    

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 mx-auto">
            <div className="row" style={{ paddingBottom: "0px" }}>
              <div className="col-lg-12 text-left">
                <div style={{ height: "77px" }}></div>
                <div className="card mb-8 shadow">
                <div className="card-header bg-dark text-white pb-1">
                            <h4><i className="fas fa-file-signature"></i> My Orders</h4>
                            
                </div>
                  <div className="card-body" style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "15px" }}>
                    
                    
                    {(() => {
                     if(this.state.info !== "")
                     {
                          return (
                              <div dangerouslySetInnerHTML={ {__html: this.state.info} } />
                            )
                     }
                     })()}
                    

                    {this.state.lastOrders.map((booking,index) => {
                    
                    return (
                        <div  key={index} className="bd-callout bd-callout-theme shadow-sm rounded" style={{
                            marginTop: '15px',
                            marginBottom: '15px'
                        }} >
                                <div dangerouslySetInnerHTML={ {__html: booking.booking } } />
                        </div>
                    )

                    })}

                    



                    
                  </div>

                  </div>
                     
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "60px"}}></div>  
        </div>
      </section>
    	)
  }
}

export default LastOrder;