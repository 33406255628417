import React, { useEffect, useState, useRef } from 'react';
import {BrowserRouter,Route, Switch} from 'react-router-dom';


import Home from './components/Home/Home';
import Category from './components/Category/Category';
import Categories from './components/Category/Categories';
import Product from './components/Product/Product';
import Menu from './components/Menu/Menu';
import Footer from './components/Footer/Footer';
import Checkout from './components/Checkout/Checkout';
import Receipt from './components/Receipt/Receipt';
import Page from './components/Page/Page';
import NotFound from './components/NotFound/NotFound';
import Booking from './components/Booking/Booking';
import LastOrder from './components/LastOrder/LastOrder';
import MenuBottom from './components/Menu/MenuBottom';

import 'bootstrap';
import 'jquery-easing';
import './assets/css/vertikaltrip.scss';
import './assets/css/custom.scss';

import { Plugins, Capacitor } from "@capacitor/core";
import { MobileAccessibility } from '@ionic-native/mobile-accessibility';

import uuid from 'react-uuid';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

function App() {

  const [setting, setSetting] = useState([]);
  const renderAfterCalled = useRef(false);

  
  useEffect(() => {
      
      
      
      
    
    window.$ = window.jQuery = require('jquery');
    
    if (Capacitor.isNative) {
        Plugins.App.addListener("backButton", (e) => {
          let ans = window.confirm("Are you sure");
            if (ans) {
              Plugins.App.exitApp();
            } 
        });
    }

    let trackingCode =  new URLSearchParams(window.location.search).get("ref");
    if(trackingCode===null)
    {
       trackingCode = localStorage.getItem('trackingCode');
    }


    const getSetting = async () => {
        let trackingCodeParam = '';
        if(trackingCode!=="null")
        {
          trackingCodeParam = '?trackingCode='+trackingCode;
        }

        try{
          const response = await fetch(process.env.REACT_APP_API_URL+'/config'+ trackingCodeParam);
          const result = await response.json();
          setSetting(result);
        } catch(err)  {
          
          
            window.location.reload();
          
       
        }
        
    };

    getSetting();


                
           

  }, []);


    

    

    MobileAccessibility.setTextZoom(100);
    MobileAccessibility.updateTextZoom();
    MobileAccessibility.usePreferredTextZoom(false);

    
    if (localStorage.getItem('sessionId') === null || localStorage.getItem('sessionId') === "") {
        localStorage.setItem('sessionId', uuid());
    }


   
    

    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: "https://"+ process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };
    initializeApp(firebaseConfig);
    
    

    if(setting.length===0)
    {
        return (
          <div style={{ backgroundColor:"#FFFFFF", top:"0", bottom:"0", left:"0", right:"0", margin:"0px", position:"absolute", height:"100%"}}>
            <div style={{ backgroundColor:"#FFFFFF", top:"50%", left:"50%", marginTop:"-50px", marginLeft:"-50px", position:"absolute"}}>
              <div className="loader mt-0 mb-4" />
              <div className="text-theme text-center"><h5>Loading...</h5></div>
            </div>
          </div>
        )
    }
    

    

    Object.keys(setting).forEach((key) => {
        if(typeof setting[key]==="object")
        {
          localStorage.setItem(key, JSON.stringify(setting[key]));
        }
        else
        {
          localStorage.setItem(key, setting[key]);
        }
        
    });

    if (!renderAfterCalled.current) {
      Object.keys(setting.jscripts).forEach((key) => {
                const script = document.createElement("script");
                script.src = setting.jscripts[key][0];
                script.async = setting.jscripts[key][1];
                document.head.appendChild(script);
      });
    }
    renderAfterCalled.current = true;
    
    if(localStorage.getItem('analytic')=== 'true')
    {
      getAnalytics();
    }
    
    




  return (
    <BrowserRouter>
       <Menu />
       <div className="bg-white" style={{ minHeight: "600px"}}>
       <div style={{ height: "50px"}}></div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/page/not/found" component={NotFound} />
          <Route path="/page/:slug" component={Page} />
          <Route path="/tours/:slug" component={Category} />
          <Route path="/tours" component={Categories} />
          <Route path="/tour/:slug" component={Product} />
          <Route path="/booking/lastorder" component={LastOrder} />
          <Route path="/booking/checkout" component={Checkout} />
          <Route path="/booking/receipt/:id/:slug" component={Receipt} />
          <Route path="/booking/:slug" component={Booking} />
          <Route path="/home" component={Categories} />
        </Switch>
       </div>
       <Footer />
       <MenuBottom />
  </BrowserRouter>
  );
}

export default App;
